<template>
  <div class="page-layout__inner">
    <div :class="'page-layout__filter' + (filterSettings.visible ? '' : ' w-auto')">
      <section :class="'section  section--shadow-0  section--p-0' + (filterSettings.visible ? ' h-100' : '')">
        <button @click="filterSettings.visible = true" :class="'section__filter-btn' + (filterSettings.visible ? ' section__filter-btn--hidden' : '')">
          <svg class="section__filter-icon">
            <use xlink:href="#icon__filter"></use>
          </svg>
        </button>
        <div :class="'section__filter' + (filterSettings.visible ? '' : ' section__filter--hidden')">
          <div class="section__header">
            <h2 class="section__title">
              <svg class="section__filter-icon">
                <use xlink:href="#icon__filter"></use>
              </svg>
              {{ getTranslation('filter') }}
            </h2>

            <div class="section__controls">
              <div class="section__controls-group">
                <a @click="filterSettings.visible = false" class="btn btn-link " href="javascript:void(0)">{{ getTranslation('hide') }}</a>
                <a class="btn  btn-link" href="javascript:void(0)" @click="resetFilter">{{ getTranslation('reset') }}</a>
              </div>
            </div>
          </div>
          <div class="section__body">
            <div class="form-group">
              <form method="get" @submit.prevent="fetchItems(1)">
                <label class="form__label form__label--block">{{ getTranslation('search') }}</label>
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('type') }}</label>
              <VSelect
                  :options="filterOptions.types"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="filterForm.typeId"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>

            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('status') }}</label>
              <VSelect
                  :options="filterOptions.statuses"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="filterForm.status"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page-layout__content">
      <p class="section__descr">{{ getTranslation('resultFoundQuantity', pagination.total) }}</p>
      <section class="section mt-2" v-for="item in items" v-bind:key="item.id">
        <div class="section__header">
          <h3 class="section__title">
            <router-link :to="{name:'company.get',params:{id:item.id}}"> {{ item.title }}</router-link>
          </h3>
          <div class="section__controls">
            <div class="section__controls-group">
              <span v-if="item.status === 'NEW'" class="badge" :class="{'badge-success':item.status === 'NEW'}">{{ getTranslation('COMPANY_' + item.status) }}</span>
            </div>
          </div>
        </div>
        <div class="section__body">

        </div>
      </section>
      <CPagination
          v-if="pagination.last_page > 1"
          class="mt-5"
          :active-page.sync="pagination.current_page"
          :pages="pagination.last_page"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      filterSettings: {
        visible: true
      },
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      filterForm: {
        status: this.$route.query.status || ''
      },
      filterOptions: {
        statuses: [],
        types: [],
      },
    }
  },
  created() {

    let actions = [];

    if ( this.hasPermission('company.create') )
      actions.push(
          {
            label: this.getTranslation('addCompany'),
            action: () => {
              this.$router.push({ name: 'company.create' }).catch(() => {
              });
            },
            icon: {
              icon: '#icon__shape-add'
            }
          }
      );

    this.setHeadings({
      title: this.getTranslation('companies'),
      breadcrumbs: [
        {
          to: { name: 'company.getList' },
          text: this.getTranslation('companies')
        }
      ],
      actions: actions
    });

    this.fetchItems(this.pagination.current_page);
    this.fetchFilterOptions();

  },
  watch: {
    'pagination.current_page'(val) {
      this.fetchItems(val);
    },
    'filterForm.status'() {
      this.fetchItems(1);
    },
    'filterForm.typeId'() {
      this.fetchItems(1);
    }
  },
  methods: {
    fetchFilterOptions() {
      this.axios.get(this.$urls.company.filterOptions.url)
          .then(response => {
            this.filterOptions.statuses = this.objectToArray(response.data.data.statuses, false, true);
            this.filterOptions.types = response.data.data.types.map(item => {
              return {
                id: item.id,
                title: this.getTranslation(item.slug)
              }
            })
          }).catch(() => {
      })
    },
    resetFilter() {

    },
    fetchItems(page) {

      let params = this.cleanObject({ ...this.filterForm });

      if ( page )
        params.page = page;

      this.axios.get(this.$urls.company.companies.url, { params: params })
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(()=>{});
    }
  }
}
</script>

<style scoped>

</style>